import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { ReactCountryFlag } from 'react-country-flag';
import $ from 'jquery'
import API from '../../../Services/API';
import { Link, useParams } from 'react-router-dom'
import Styles from './countryview.module.css'
import {
    Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Input, Textarea, Button, Image,
    Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Stack} from '@chakra-ui/react'
import BreadCrumbs from '../../Widgets/BreadCrumbs/BreadCrumbs';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import MapTabs from './MapTabs/MapTabs';
import Metric from './Metric/Metric';
import { EditContext } from '../../../Context/BreadcrumbsContext';
import StateImage from './MapTabs/MetricsMap/StateImage/StateImage';

const statesOfUSA = [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "American Samoa", code: "AS" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Commonwealth of the northen", code: "MP" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "District of columbia", code: "DC" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Guam", code: "GU" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "United States Virgin Islands", code: "VI" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" }
  ];
  

const StateView = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(true)
    const [editable, setEditable] = useState();
    const [action, setAction] = useState();
    const {stateId} = useParams();
    const editor = useRef(null);
    const [file, setFile] = useState(null);
    const [countryInfo, setCountryInfo] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [stateCount, setStateCount] = useState(null);
    const {edit, setEdit, hasAllPermission } = useContext(EditContext);
    const [email, setEmail] = useState(null);
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        API.get(`/user-management`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
          setEmail(response.data.email)
        }).catch(error=>{
          setIsLoading(false)
        })
	
        API.get(`/get-specific-modules?module_id=${stateId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
      }).then((response) => {
          setCountryInfo(response.data)
      })
        API.get(`/project-modules?module_id=${stateId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/get-sub-modules?module_id=${stateId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response1) => {
                setStateCount(response1.data.length)
                setCountryList(response1.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
            // setCountryInfo(response.data[0])
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
    },[stateId])

    const geteditStatus = (isEdit) =>{
        setEditable(isEdit)
    }

    const updatePageInfo = (event) => {
        setIsLoading(true)
        const formData = new FormData()
        if(file != null){
            formData.append('banner', file)
        }
        formData.append('name', $("#pagetitle").val())
        formData.append('description', $("#description").val())
        formData.append('id', countryInfo && countryInfo.id)
        API.put(`/project-modules`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/project-modules?module_id=${stateId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response1) => {
                // setCountryInfo(response1.data[0])
                setIsLoading(false)
            })
        })
    }
    function bannerUpload(e) {
        setFile(e.target.files[0]);
    }

    const countrycodeforusaonly =statesOfUSA.find((a)=>a?.name===countryInfo?.name )

    return (
      <>
        {isLoading ? <LoaderSpinner /> : null}
        <BreadCrumbs
          geteditStatus={geteditStatus}
          title={countryInfo && countryInfo.name}
          pillarId={action && action.id}
        />
        <Card mb={"15px"}>
          <CardBody>
            {edit && 
                        (hasAllPermission
                        ) ?  (
              <>
                <Stack spacing={3} mb={"30px"}>
                  <Input
                    id={"pagetitle"}
                    placeholder="Please Enter Title"
                    size="md"
                    backgroundColor={"#ffffff"}
                    defaultValue={countryInfo && countryInfo.name}
                  />
                  <Input type="file" onChange={bannerUpload} padding={"4px"} />
                 {countryInfo && countryInfo.banner && <Image
                    src={countryInfo && countryInfo.banner}
                    alt={countryInfo && countryInfo.name}
                  />}
                  {/* <JoditEditor
                                        id={"description"}
                                        ref={editor}
                                        value={props.info && props.info.description}
                                        tabIndex={1} // tabIndex of textarea
                                        onChange={newContent => {}}
                                    /> */}
                  <Button
                    colorScheme="blue"
                    w={100}
                    onClick={updatePageInfo}
                  >
                    Save
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                {countrycodeforusaonly ? (
                  <img
                    src={`/usa/${
                      countrycodeforusaonly?.code
                        ? countrycodeforusaonly?.code.toLowerCase()
                        : ""
                    }.png`}
                    className={Styles.flagImage}
                    alt={''}
                  />
                ) : (
                  <>
                    <ReactCountryFlag
                      countryCode={countryInfo && countryInfo.country_code}
                      svg
                      className={Styles.flagImage}
                    />
                    <Image
                      src={countryInfo && countryInfo.banner}
                      alt={countryInfo && countryInfo.country_code}
                    />
                  </>
                )}
              </>
            )}
          </CardBody>
        </Card>
        {stateCount && stateCount > 0 ? (
          <MapTabs code={countryInfo && countryInfo.country_code} />
        ) : null}
        <Metric />
      </>
    );
}

export default StateView