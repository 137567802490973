import React, { useState } from 'react'
import Styles from './info.module.css'
import { Text, Heading, Stack, Image } from '@chakra-ui/react'
import parse from 'html-react-parser';

const Info = (props) => {
    return (
        <Stack spacing={6} mb={'10px'}>
            {/* <Heading as='h2' fontSize={"20px"} fontWeight={"500"}>
                {props.info && props.info.prop_label}
            </Heading> */}
           {props.info && props.info.banner && <Image src={props.info && props.info.banner} alt={props.info && props.info.prop_label} />}
            {props.info && props.info.description != null  ? parse(props.info && props.info.description) : ''}
        </Stack>
    )
}

export default Info