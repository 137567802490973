import React, { useState, useEffect } from 'react';
import Styles from '../metricdata.module.css';
import API from '../../../../Services/API';
import Select from 'react-select';
import { 
    useDisclosure, 
    Button, 
    Tooltip, 
    IconButton, 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalFooter, 
    ModalBody, 
    ModalCloseButton, 
    FormControl, 
    FormLabel, 
    Input, 
    SimpleGrid 
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget'

const EditMetricData = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);

    // Initial default values from props
    const [defaultValues, setDefaultValues] = useState({
        periodName: props.periodName,
        metricName: props.metricName,
        period: props.period,
        fyq: props.fyq,
        status: props.status,
        approvalStatus: props.approvalStatus,
        geography: props.geography
    });

    // State variables for the modal
    const [selectedMetric, setSelectedMetric] = useState(props.metricName);
    const [selectedPeriod, setSelectedPeriod] = useState(props.period);
    const [selectedPeriodName, setSelectedPeriodName] = useState(props.periodName);
    const [selectedFyq, setSelectedFyq] = useState(props.fyq);
    const [selectedFyqName, setSelectedFyqName] = useState(props.fyqName);

    const [selectedStatus, setSelectedStatus] = useState(props.status);
    const [selectedApproval, setSelectedApproval] = useState(props.approvalStatus);
    const [selectedCountry, setSelectedCountry] = useState(props.geography);
    const [selectedCountryName, setSelectedCountryName] = useState(props.geographyName);
    const [qtrListUp, setQtrListUp] = useState([]);
    const [strategy, setStrategy] = useState(props.strategy);
    const [strategyName, setStrategyName] = useState(props.strategyName);

    const [message, setMessage] = useState(null)

    
    // Prepare fiscal year options
    const fiscalYearDetail = props.fiscalYearDetail?.map(item => ({
        value: item.id,
        label: item.name
    })).sort((a, b) => parseInt(b.label.replace('FY', '')) - parseInt(a.label.replace('FY', '')));

    // Prepare fiscal quarter options
    const fiscalYearQtrDetail = props.fiscalYearQtrDetail?.map(item => ({
        value: item.id,
        label: item.name
    }));

    // Prepare country options
    const countriesList = props.countries && props.countries?.map(region => ({
        value: region.id,
        label: region.name
    })).sort((a, b) => a.label.localeCompare(b.label));

    const strategiesList = props.strategies?.map(item => ({
        value: item.id,
        label: item.name
    }))

    // Update quarters dynamically when Fiscal Year changes
    const updateQuarterList = (periodName) => {
        const selectedOptionNew = `FY${periodName.slice(-2)}`;
        const relatedQuarters = fiscalYearQtrDetail?.filter(item => item.label.includes(selectedOptionNew))
            .sort((a, b) => {
                const quarterA = parseInt(a.label.split(' ')[1].replace('Q', ''));
                const quarterB = parseInt(b.label.split(' ')[1].replace('Q', ''));
                return quarterB - quarterA;
            });
        setQtrListUp(relatedQuarters || []);
    };

    // Reset to default values when modal is opened
    const handleOpen = () => {
        setSelectedPeriodName(defaultValues.periodName);
        setSelectedFyqName(defaultValues.fyqName)
        setSelectedMetric(defaultValues.metricName);
        setSelectedPeriod(defaultValues.period);
        setSelectedFyq(defaultValues.fyq);
        setSelectedStatus(defaultValues.status);
        setSelectedApproval(defaultValues.approvalStatus);
        setSelectedCountry(defaultValues.geography);
        updateQuarterList(defaultValues.periodName);
        onOpen();
    };

    // Handle period (FY) change
    const changePeriod = (event) => {
        setSelectedPeriod(event.value); // Update Fiscal Year
        setSelectedPeriodName(event.label);
        setSelectedFyq(null); // Reset Fiscal Quarter
        updateQuarterList(event.label); // Update quarters based on the new Fiscal Year
    };

    // Handle fiscal quarter (FQ) change
    const changeFyq = (event) => {
        setSelectedFyq(event.value);
        setSelectedFyqName(event.label)
    };

    // Handle save
    const handleSave = async () => {
        setMessage(null);
    
        // Avoid direct DOM manipulation
        const updatedValue = document.getElementById("updateValue")?.value || props.value;
    
        const hasChanges = 
            parseInt(updatedValue) !== parseInt(props.value) ||
            selectedPeriod !== props.period ||
            selectedFyq !== props.fyq ||
            selectedStatus !== props.status ||
            selectedCountry !== props.geography ||
            strategy !== props.strategy;
    
        if (!hasChanges) {
            setMessage("No changes are made");
            return;
        }
    
        try {
            setIsLoading(true);
    
            const body = {
                id: parseInt(props.id),
                name: selectedMetric,
                value: parseInt(updatedValue),
                ...(selectedPeriod && { 
                    fiscal_year: parseInt(selectedPeriod), 
                    fiscal_year_name: selectedPeriodName 
                }),
                ...(selectedFyq && { 
                    fiscal_quarter: parseInt(selectedFyq), 
                    fiscal_quarter_name: selectedFyqName 
                }),
                ...(selectedStatus && { metric_status: selectedStatus }),
                ...(strategy && { strategy: strategy}),
                ...(strategyName && { strategy_name: strategyName }),
                ...(selectedCountry && {geography: selectedCountry}),
                ...(selectedCountryName && {geography_name: selectedCountryName})
            };
    
            await API.put(`/metric-data`, body, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage("Metric updated successfully");
            const response = await API.get(`/my-task`, {headers: { Authorization: `Bearer ${token}` },});
            props.getUpdatedPending(response.data);
            props.getMessage(true);
            onClose();
        } catch (error) {
            console.error("Error updating metric:", error);
            setMessage(error.response?.data?.message || "An error occurred during the update");
            setMessage(error.response?.data?.msg)
            onClose();
        } finally {
            setIsLoading(false);
            onClose();
        }
    };
    

    const statusOptions = [
        { value: 'on-track', label: 'On Track' },
        { value: 'delay', label: 'Delayed' },
        { value: 'acceleration', label: 'Acceleration' }
    ];
    const changeStatus = (event) => {
        setSelectedStatus(event.value);
    };

    const changeCountry = (event) => {
        setSelectedCountry(event?.value);
        setSelectedCountryName(event?.label)
    };

    const changeStrategy = (event) => {
        setStrategy(event?.value);
        setStrategyName(event?.label)
    };

    

    return (
        <>
            {
                isLoading ? <LoaderSpinner /> : null
            }
            {
                message ? <ErrorWidget message={message} /> : null
            }
            <Tooltip id={props.id} label="Edit metric" aria-label="Edit metric">
                <IconButton
                    variant="solid"
                    aria-label="Edit"
                    fontSize="15px"
                    icon={<EditIcon />}
                    className={Styles.edit}
                    id={props.id}
                    onClick={handleOpen}
                />
            </Tooltip>

            {/* Modal Dialog */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="800px">
                    <ModalHeader>Edit Metric Data</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SimpleGrid columns={[1, 2]} spacing={4}>
                            <FormControl id="metric-name">
                                <FormLabel>Metric Name</FormLabel>
                                <Input 
                                    value={selectedMetric} 
                                    isDisabled 
                                    aria-label="Metric Name"
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Value</FormLabel>
                                <Input id="updateValue" defaultValue={props.value} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Period (FY)</FormLabel>
                                <Select
                                    options={fiscalYearDetail}
                                    defaultValue={fiscalYearDetail && fiscalYearDetail.find(option => option.label === selectedPeriodName)}
                                    onChange={changePeriod}
                                    aria-label="Period Fiscal Year"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Period (FQ)</FormLabel>
                                <Select
                                    options={qtrListUp}
                                    value={qtrListUp?.find(option => option.value === selectedFyq) || null}
                                    onChange={changeFyq}
                                    aria-label="Period Fiscal Quarter"
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Status</FormLabel>
                                <Select
                                    options={statusOptions}
                                    onChange={changeStatus}
                                    defaultValue={statusOptions && statusOptions.find(option => option.value === selectedStatus)}
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Geography</FormLabel>
                                <Select
                                    options={countriesList}
                                    onChange={changeCountry}
                                    defaultValue={
                                        countriesList &&
                                        countriesList.find(option => {
                                            let formattedLabel;
                                            if (option.label === "Georgia (United States of America)") {
                                                formattedLabel = option.label
                                                    .replace(/\s?\(United States of America\)/i, " ")
                                                    .replace(/^./, str => str.toUpperCase());
                                            } else {
                                                formattedLabel = option.label
                                                    .replace("(United States of America)", "")
                                                    .trim();
                                            }
                                            return formattedLabel === props.geographyName;
                                        })
                                    }
                                />
                            </FormControl>
                            <FormControl mb={'15px'}>
                                <FormLabel fontWeight={'bold'}>Strategy</FormLabel>
                                <Select
                                    options={strategiesList}
                                    onChange={changeStrategy}
                                    defaultValue={strategiesList && strategiesList.find(option => option.label === strategyName)}
                                />
                            </FormControl>
                        </SimpleGrid>
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" mr={3} onClick={handleSave} isLoading={isLoading}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditMetricData;
