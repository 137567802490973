import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../Services/API';
import {
  Text,
  Heading,
  Input,
  Stack,
  Button,
  Image,
  useToast,
} from '@chakra-ui/react';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';


const TopSection = (props) => {
  const token = window.localStorage.getItem('accessToken');
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [isMessage, setIsMessage] = useState(null);
  const [pageTitle, setPageTitle] = useState('');
  const [description, setDescription] = useState('');
  const editor = useRef(null);
  const { id } = useParams();
  const { hasAllPermission } = useContext(EditContext);
  const toast = useToast();

  

  // Load initial values from props
  useEffect(() => {
    if (props.fiscalYearInfo?.length > 0) {
      setPageTitle(props.fiscalYearInfo[0].prop_label || '');
      setDescription(props.fiscalYearInfo[0].description || '');
    }
  }, [props.fiscalYearInfo]);

  const bannerUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const updateFiscalYearInfo = async () => {
    // Check if there are any changes
    const originalTitle = props.fiscalYearInfo?.[0]?.prop_label || '';
    const originalDescription = props.fiscalYearInfo?.[0]?.description || '';
    const originalBanner = props.fiscalYearInfo?.[0]?.banner || '';

    if (
      pageTitle === originalTitle &&
      description === originalDescription &&
      !file
    ) {
      setIsMessage('No changes are made.');
      //toast({ title: 'No changes are made.', status: 'info' });
      return;
    }

    setIsLoading(true);
    setIsMessage(null);
    const formData = new FormData();
    if (file) formData.append('banner', file);
    formData.append('prop_label', pageTitle);
    formData.append('description', description);

    try {
      if (!props.fiscalYearInfo || props.fiscalYearInfo.length < 1) {
        await API.post(`/fiscal-year-info/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        //setIsMessage('Fiscal year saved successfully.');
        toast({ title: 'Fiscal year saved successfully', status: 'success' });
      } else {
        formData.append('id', props.fieldId);
        await API.put(`/fiscal-year-info/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const updateModuleForm = new FormData();
        updateModuleForm.append('id', id);
        updateModuleForm.append('name', pageTitle);
        await API.put(`/project-modules`, updateModuleForm, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setIsMessage('Fiscal year updated successfully.');
        //toast({ title: 'Fiscal year updated successfully', status: 'success' });
        window.location.reload();
      }
    } catch (error) {
      setIsMessage('Error saving fiscal year.');
      //toast({ title: 'Error saving fiscal year', status: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderSpinner />}

      {
        isMessage && (
          <ErrorWidget message={isMessage} />
        )
      }
      {/* {isMessage && (
        <Text color="green.500" fontSize="sm" mb={4}>
          {isMessage}
        </Text>
      )} */}
      {props.editable && hasAllPermission ? (
        <Stack spacing={3} mb="30px">
          <Input
            placeholder="Please add fiscal year info here..."
            size="md"
            backgroundColor="#ffffff"
            value={pageTitle}
            onChange={(e) => setPageTitle(e.target.value)}
          />
          <Input type="file" onChange={bannerUpload} padding="4px" />
          {props.fiscalYearInfo?.[0]?.banner && (
            <Image
              src={props.fiscalYearInfo[0].banner}
              alt={props.fiscalYearInfo[0].prop_label}
            />
          )}
          <JoditEditor
            ref={editor}
            value={description}
            tabIndex={1}
            onChange={(content) => setDescription(content)}
          />
          <Button w={100} onClick={updateFiscalYearInfo}>
            Save
          </Button>
        </Stack>
      ) : (
        <Stack spacing={6} mb="30px">
          <Heading as="h2" fontSize="20px" fontWeight="600">
            {props.fiscalYearInfo?.[0]?.prop_label}
          </Heading>
          {props.fiscalYearInfo?.[0]?.banner && (
            <Image
              src={props.fiscalYearInfo[0].banner}
              alt={props.fiscalYearInfo[0].prop_label}
            />
          )}
          <Text as="p">
            {props.fiscalYearInfo?.[0]?.description
              ? parse(props.fiscalYearInfo[0].description)
              : ''}
          </Text>
        </Stack>
      )}
    </>
  );
};

export default TopSection;
