import React, {useContext, useState, useEffect, useRef, useMemo } from 'react';
import { ReactCountryFlag } from 'react-country-flag';
import { fromCountryName } from 'iso-3166-1-alpha-2';
import { getAlpha2Code } from 'i18n-iso-countries';
import Styles from './regions.module.css'
import $ from 'jquery'
import { Link, useParams } from 'react-router-dom';
import API from '../../Services/API';
import { Heading, Card, CardBody, Box, Image, Text, Input, Textarea, Button, Tabs, TabList, TabPanels, Tab, TabPanel, SimpleGrid, FormLabel} from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import WorldMap from 'react-svg-worldmap';
import Breadcrumb from '../Widgets/BreadCrumbs/BreadCrumbs';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import RegionMap from './RegionMap/RegionMap';
import { EditContext } from "../../Context/BreadcrumbsContext";

const Regions = () => {
    const token = window.localStorage.getItem("accessToken")
    const mapRef = useRef(null);
    const [initialScale, setInitialScale] = useState(1.2);
    const [isLoading, setIsLoading]  = useState(false)
    const [editable, setEditable] = useState();
    const [moduleInfo, setModuleInfo] = useState();
    const [actions, setActions] = useState();
    const {id} = useParams();
    const [pillarId, setPillarId] = useState()
    const [pillarTeam, setPillarTeam] = useState()
    const [metric, setMetric] = useState()
    const [initiative, setInitiative] = useState()
    const [countryList, setCountryList] = useState()
    const {edit, setEdit, hasAllPermission } = useContext(EditContext);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        setIsLoading(true)

        API.get(`/user-management`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
          setEmail(response.data.email)
        }).catch(error=>{
          setIsLoading(false)
        })

        API.get(`/get-specific-modules?module_id=${id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setModuleInfo(response.data)
        })

        API.get(`/project-modules?module_id=${id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            // setModuleInfo(response.data[0])
            window.localStorage.removeItem("pillarId")
            window.localStorage.setItem("pillarId", response.data && response.data.id)
            setPillarId(response.data && response.data.id)
            API.get(`/get-sub-modules?module_id=${id}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response1) => {
                setCountryList(response1.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })

        if (mapRef.current) {
            const mapWidth = mapRef.current.offsetWidth;
            const containerWidth = mapRef.current.parentElement.offsetWidth;
            const mapHeight = mapRef.current.offsetHeight;
            const containerHeight = mapRef.current.parentElement.offsetHeight;

            const scaleX = containerWidth / mapWidth;
            const scaleY = containerHeight / mapHeight;
            const initialScale = Math.min(scaleX, scaleY);
            setInitialScale(initialScale);
        }
        
    },[id])

    const geteditStatus = (isEdit) =>{
        setEditable(isEdit)
    }

    const dataNew = countryList && countryList.map(item =>
        ({country: item.country_code !== null ? item.country_code.toLowerCase() : '' , value: 52220})
    )

    const updateInfo = (event) => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('subtitle', $("#subtitleRegion").val())
        formData.append('id', id)
        API.put(`/project-modules`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    const sortedList = countryList && countryList.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
            {
                isLoading ? <LoaderSpinner /> : null
            }
            <Breadcrumb geteditStatus={geteditStatus} title={moduleInfo && moduleInfo.name} pillarId={pillarId && pillarId} />

            {
                moduleInfo && moduleInfo.subtitle ? 
                <Heading as='h3' size='lg' mb={'25px'} fontSize={'19px'} mt={'20px'} className={Styles.customHeadingH3}>
                    Subtitle
                </Heading> : null
            }

            
            <Box mb={'15px'}>
                {
                    edit && 
                    (hasAllPermission
                    ) ? 
                        <>
                            <FormLabel fontWeight={'bold'}> Subtitle </FormLabel>
                            <Input mb={'10px'} defaultValue={moduleInfo && moduleInfo.subtitle} placeholder='Provide subtitle' id='subtitleRegion' /> 
                            <Button className='buttonPrimary' onClick={updateInfo}> Save </Button>
                        </>
                    : <Text> {moduleInfo && moduleInfo.subtitle} </Text>
                }
            </Box>
            
            <Card>
                <CardBody>
                    <Heading as='h3' size='lg' mb={'25px'} fontSize={'19px'} mt={'0px'} className={Styles.customHeadingH3}>
                        Map Tabs
                    </Heading>
                    <Tabs variant='underline'>
                        <TabList defaultIndex={0}>
                            <Tab   className={Styles.tabsGroup} _selected={{ color: "#00aae0", borderBottom: "2px solid #00aae0",}}>Metrics map</Tab>
                            {/* <Tab className={Styles.tabsGroup} _selected={{ color: "#00aae0", borderBottom: "2px solid #00aae0",}}>Impact Gallery map 2</Tab> */}
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <RegionMap dataNew={dataNew && dataNew} />
                                <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing='20px' mt={'25px'}>
                                    {
                                        sortedList && sortedList.map(country =>
                                            // country.country_code!=null &&  <Box className='countryCon'>
                                            <Box className='countryCon'>
                                                <Link to={`country/${country.id}/${country.name}`}>
                                                    <Card boxShadow={'none'}>
                                                        <CardBody p={'0px'} className='countryConBor'>
                                                            <Box float={'left'} mr={'5px'} className={'flagContainer'}>
                                                                <ReactCountryFlag countryCode={country.country_code} svg className={'flagImage'} />
                                                            </Box>
                                                            <Box float={'left'} w={'68%'} className={'countryName'}>
                                                                <Text className='countryHeading'> Country </Text>
                                                                <Text className='countryTitle'> {country.name} </Text>
                                                            </Box>
                                                            <Box className={Styles.clearfix}></Box>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </Box>
                                        )
                                    }
                                </SimpleGrid>
                            </TabPanel>
                            <TabPanel>
                                <p>Data not available now. Please check back later</p>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </CardBody>
            </Card>
        </>
    )
}

export default Regions