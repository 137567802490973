import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Styles from './createmetric.module.css';
import { useParams } from 'react-router-dom';
import { Box, Button, FormControl, FormLabel, Input, SimpleGrid, Switch, Textarea } from '@chakra-ui/react';
import {AddIcon} from '@chakra-ui/icons'

import API from '../../../../Services/API';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import JoditEditor from 'jodit-react';
import ErrorWidget from '../../../Widgets/ErrorWidget/ErrorWidget';


const CreateMetric = (props) => {
    const token = window.localStorage.getItem('accessToken');
    const [state, setState] = useState({ isPaneOpen: false });
    const [isLoading, setIsLoading] = useState(false);
    const editor = useRef(null);
    const [message, setMessage] = useState(false);
    const [pillarAction, setPillarAction] = useState([]);
    const [strategy, setStrategy] = useState([]);
    const [strategies, setStrategies] = useState([]);
    const [isQbr, setIsQbr] = useState(false);
    const [isMap, setIsMap] = useState(false);
    const [allow, setAllow] = useState(false);
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);

    const [selectedDataType, setSelectedDataType] = useState(null);
    const [selectedMetricType, setSelectedMetricType] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    
    const [formErrors, setFormErrors] = useState({
        metricName: '',
        dataType: '',
        metricType: '',
        aggregate: '',
    });

    useEffect(() => {
        setIsLoading(true);
        API.get(`/pillar?module_id=${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then(response => {
            const pillarId = response.data.id;
            return Promise.all([
                API.get(`/action?pillar_id=${pillarId}`, { headers: { 'Authorization': 'Bearer ' + token } }),
            ]);
        })
        .then(([actionsResponse]) => {
            setPillarAction(actionsResponse.data);
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            setIsLoading(false);
        });
        
    }, [id, token]);

    const openSlidingPane = () => {
        setState({ isPaneOpen: true });
    };

    const handleSwitchChange = (setter) => (event) => {
        setter(event.target.checked);
    };

    const validateForm = () => {
        const errors = {};
        if (!document.getElementById('metricname').value) {
            errors.metricName = 'Metric name is required.';
        }
        if (!selectedDataType) {
            errors.dataType = 'Data type is required.';
        }
        if (!selectedMetricType) {
            errors.metricType = 'Metric type is required.';
        }
        return errors;
    };

    const newMetric = () => {
        const errors = validateForm();
        setFormErrors(errors);
        
        if (Object.keys(errors).length > 0) return;

        setIsLoading(true);
        const formData = new FormData();
        formData.append("name", document.getElementById('metricname').value);
        formData.append("description", document.getElementById('metricdescription').value);
        formData.append("datatype", selectedDataType.value);
        formData.append("metrictype", selectedMetricType.value);
        formData.append("aggregate", 'last');
        formData.append("map", isMap);
        formData.append("pillar_id", props.pillarMetricId);
        formData.append("allow_export_externally", allow);
        formData.append("dashboard", true);
        formData.append("elr", isQbr);
    
        if(strategy){
            formData.append("strategy", strategy.value);
            formData.append("strategy_name", strategy.label);
        }

        if (selectedAction) {
            formData.append("action_id", selectedAction.value);
        }
        API.post(`/metric`, formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then(() => {
            setState({ isPaneOpen: false });
            setMessage(true);
            props.getUpdatedMetrics()
        })
        .catch(error => {
            console.error(error);
            setErrorMessage('An error occurred while creating the metric.');
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    const dataTypes = [
        { value: 'percent', label: 'Percent' },
        { value: 'number', label: 'Number' },
        { value: 'money', label: 'Money' },
    ];

    const metricTypes = [
        { value: 'operational', label: 'Operational' },
        { value: 'financial', label: 'Financial' },
        { value: 'impact', label: 'Impact' },
    ];

    const actions = pillarAction.filter(item => item.visible).map(item => ({
        value: item.id,
        label: item.name,
    }));

    const selectAction = (event)=>{
        if(!event){
            return
        }
        setSelectedAction(event)
        API.get(`/strategy?action_id=${event.value}`, { 
            headers: { 'Authorization': 'Bearer ' + token } 
        })
        .then(response => {
            console.log('Response:', response.data);
            setStrategies(response.data)
        })
        .catch(error => {
            console.error('Error fetching strategy:', error);
        });
    }

    const strategiesOptions = strategies.map((strategy) => ({
        value: strategy.id,
        label: strategy.name,
    }));

    const selectStrategy = (event) =>{
        setStrategy(event);
        if(!event){
            return
        }
    }




    return (
        <>
            {isLoading && <LoaderSpinner />}
            <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' float={'right'} onClick={openSlidingPane}>
                Add Metric
            </Button>
            {
                message && <ErrorWidget message={'Created Metric successfully, Please reload the page to see the changes'} />
            }
            <SlidingPane
                className={Styles.slidingPane}
                isOpen={state.isPaneOpen}
                title="Create Metric"
                width="50%"
                onRequestClose={() => setState({ isPaneOpen: false })}
            >
                {errorMessage && <Box color={'red'}>{errorMessage}</Box>}
                <SimpleGrid columns={[1, 2]} spacing='20px' mt={'10px'}>
                    <Box>
                        <FormControl isInvalid={!!formErrors.metricName}>
                            <FormLabel fontWeight={'bold'}>
                                Name <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Input 
                                type='text'
                                id='metricname'
                                placeholder='Please provide metric name'
                            />
                            {formErrors.metricName && <Box color='crimson'>{formErrors.metricName}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!formErrors.dataType}>
                            <FormLabel fontWeight={'bold'}>
                                Data Type <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Select
                                onChange={setSelectedDataType}
                                options={dataTypes}
                                isClearable
                            />
                            {formErrors.dataType && <Box color='crimson'>{formErrors.dataType}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl isInvalid={!!formErrors.metricType}>
                            <FormLabel fontWeight={'bold'}>
                                Metric Type <span className='hilightStar'>*</span>
                            </FormLabel>
                            <Select
                                onChange={setSelectedMetricType}
                                options={metricTypes}
                                isClearable
                            />
                            {formErrors.metricType && <Box color='crimson'>{formErrors.metricType}</Box>}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Action</FormLabel>
                            <Select
                                onChange={selectAction}
                                options={actions}
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Strategy</FormLabel>
                            <Select
                                onChange={selectStrategy}
                                options={strategiesOptions}
                                isClearable
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel fontWeight={'bold'} htmlFor='metricqbr' mb='0'>
                                Executive Leadership Review
                            </FormLabel>
                            <Switch id='metricqbr' onChange={handleSwitchChange(setIsQbr)} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel fontWeight={'bold'} htmlFor='metricmap' mb='0'>
                                Map 
                            </FormLabel>
                            <Switch id='metricmap' onChange={handleSwitchChange(setIsMap)} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='share_data_externally' mb='0' fontWeight={'bold'}>
                                Share Data Externally
                            </FormLabel>
                            <Switch id='share_data_externally' onChange={handleSwitchChange(setAllow)} />
                        </FormControl>
                    </Box>
                </SimpleGrid>
                <FormControl mt={4}>
                    <FormLabel fontWeight={'bold'}>Description</FormLabel>
                    <Textarea id='metricdescription' placeholder='Provide a brief description of the metric' />
                </FormControl>
                <Box mt={4}>
                    
                    <Button onClick={() => setState({ isPaneOpen: false })}>
                        Cancel
                    </Button>
                    <Button onClick={newMetric}  colorScheme='blue' mr={3}>
                        Create Metric
                    </Button>
                </Box>
            </SlidingPane>
        </>
    );
};

export default CreateMetric;
