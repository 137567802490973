import React, { useMemo } from 'react';
import { Box, Heading, Image, Text } from '@chakra-ui/react';
import Styles from './Slider.module.css';
import FooterImg from '../../../../../../../../assets/images/cisco-white.png';

const SlideZero = ({ title, firstFiscalYear, firstFiscalQtr }) => {
  // Memoize title split to avoid recomputation
  const [beforeDash, afterDash] = useMemo(() => (title ? title.split(' - ') : ['', '']), [title]);

  return (
    <Box id="pdf-content0" h="420px" className={Styles.sectionBg}>
      {/* Title Section */}
      <Box className={Styles.titleSection}>
        <Heading as="h3">
          {beforeDash && <Text className={Styles.beforeDash}>{beforeDash}</Text>}
          {afterDash && <Text className={Styles.afterDash}>{afterDash}</Text>}
          <Text className={Styles.fiscal}>
            {firstFiscalYear}
            {typeof firstFiscalQtr === 'string' && firstFiscalQtr.includes(' ')
              ? ` - ${firstFiscalQtr.split(' ')[1]}`
              : ''}
          </Text>
        </Heading>
      </Box>

      {/* Footer Section */}
      <Box className={Styles.footer}>
        <Image src={FooterImg} width="60px" float="left" alt={beforeDash} />
        <Text as="span" className={Styles.copy}>
          © 2024 Cisco and/or its affiliates. All rights reserved. Cisco confidential.
        </Text>
      </Box>
    </Box>
  );
};

export default SlideZero;
