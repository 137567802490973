import React, { useRef, useState } from 'react';
import {
    IconButton,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    useToast,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import API from '../../../../../Services/API';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const DeleteUser = ({ id, getUpdateUser }) => {
    const token = window.localStorage.getItem('accessToken');
    const [isOpen, setIsOpen] = useState(false);
    const cancelRef = useRef();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState(null)
    
    const deleteUser = async () => {
        setMessage(null)
        setIsLoading(true)
        try {
            const formData = new FormData();
            formData.append('member_id', id);
            await API.delete('team-member', {
                data: formData,
                headers: { Authorization: 'Bearer ' + token },
            });
            getUpdateUser();
            setMessage('User deleted successfully')
            setIsLoading(false)
        } catch (error) {
            console.error(error);
            setIsLoading(false)
        } finally {
            setIsOpen(false);
        }
    };

    return (
        <>
            { isLoading && <LoaderSpinner /> }
            { message && <ErrorWidget message={message && message} /> }
            {

            }
            {/* Delete Button */}
            <IconButton
                icon={<DeleteIcon />}
                aria-label="Delete User"
                size="sm"
                colorScheme="red"
                onClick={() => setIsOpen(true)}
            />

            {/* Confirmation Dialog */}
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Deletion
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to delete this user? This action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter background={'transparent'}>
                            <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="blue"
                                onClick={deleteUser}
                                ml={3}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

DeleteUser.propTypes = {
    id: PropTypes.string.isRequired,
    getUpdateUser: PropTypes.func.isRequired,
};

export default DeleteUser;
