import React,{useState} from 'react'
import $ from 'jquery'
import Styles from './newpopup.module.css'
import API from '../../../../../../Services/API'
import { PhoneIcon, AddIcon, WarningIcon, } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Box,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea, 
    Select
} from '@chakra-ui/react'
import ErrorWidget from '../../../../../../Components/Widgets/ErrorWidget/ErrorWidget'
import LoaderSpinner from '../../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
const NewPopup = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [addAnother, setAddAnother] = useState(false)
    const [errorMessage, seterrorMessage] = useState(null)
    const [isLoading, setIsLoading]  = useState(false)
    const [message, setMessage] = useState(null)
    const [fileType, setFileType] = useState(''); // State to track file type selection

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };


    const saveAndAddNew = (event) =>{
        setAddAnother(event.target.checked)
    }
console.log("props",props)
    const newArticle = () => {
        setMessage(null)
        setIsLoading(true)
        seterrorMessage(null)
        const formData = new FormData();
        formData.append('file_choices', $("#file_choices").val()); // File type (document or video)
        formData.append('name', $("#name").val()); // Guide/Video name
        formData.append('resource', $("#resource")[0].files[0]); // Resource file (if any)
        formData.append('note', $("#note").val()); // Optional note
    
        API.post(`/guides`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
        .then((response) => {
            if (addAnother) {
                setIsLoading(false)
                onOpen(); // Keep the modal open for adding another
                $("#file_choices").val(''); // Clear the file type field
                $("#name").val(''); // Clear the name field
                $("#resource").val(''); // Clear the resource file input
                $("#note").val(''); // Clear the note field
    
                API.get(`/guides`, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                })
                .then((response) => {
                  setMessage("Training guide/video has been added successfully")
                    props.getUpdated(response.data); // Update the parent component
                })
                .catch((error) => {
                    console.error("Error fetching updated articles:", error);
                    setIsLoading(false)
        
                });
            } else {
                onClose(); // Close the modal
                setIsLoading(true)
                API.get(`/guides`, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                })
                .then((response) => {
                  setIsLoading(false)
                  setMessage("Training guide/video has been added successfully.")
                  props.getUpdated(response.data); // Update the parent component
                })
                .catch((error) => {
                  setIsLoading(false)
                  console.error("Error fetching updated articles:", error);
                });
              }
            })
            .catch((error) => {
            setIsLoading(false)
            console.error("Error submitting article:", error.response.data.error);
            seterrorMessage(error && error.response && error.response.data && error.response.data.error)
            onClose();
        });
    };
    


    return (
      <>
      {isLoading ? <LoaderSpinner />: null}
      {message && message ? <ErrorWidget message={message && message} /> : null}
        {errorMessage !== null ? (
          <ErrorWidget message={errorMessage && errorMessage} />
        ) : null}
        <Box className="cardHover" mb={"30px"}>
          <Card
            height="100px"
            bg="white"
            position={"relative"}
            cursor={"pointer"}
            onClick={onOpen}
          >
            <Heading
              as="h1"
              size="sm"
              noOfLines={1}
              position={"relative"}
              top={"50%"}
              className={Styles.verticalAlign}
              textAlign={"center"}
            >
              <AddIcon boxSize={3} /> Add New
            </Heading>
          </Card>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
          <ModalOverlay className={Styles.overLay} />
          <ModalContent className={Styles.contentZindex}>
            <ModalHeader>Add New Guides / Videos</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel mt="10px">Name</FormLabel>
                <Input
                  type="text"
                  id="name"
                  mb="15px"
                  placeholder="Enter name"
                />
                <FormLabel>Type</FormLabel>
                <Select id="file_choices" mb="15px" placeholder="Select type" value={fileType}
                onChange={handleFileTypeChange}>
                  <option value="document">Document</option>
                  <option value="video">Video</option>
                </Select>

                <FormLabel mt="10px">Resource</FormLabel>
                <Input type="file" id="resource" mb="15px"  disabled={!fileType}/>

                <FormLabel mt="10px">Note</FormLabel>
                <Textarea
                  id="note"
                  mb="15px"
                  placeholder="Enter any additional notes"
                />

                <Stack spacing={5} direction="row" mt="10px">
                  <Checkbox
                    colorScheme="green"
                    defaultChecked={addAnother}
                    onChange={saveAndAddNew}
                  >
                    Save and Add Another
                  </Checkbox>
                </Stack>
              </FormControl>
            </ModalBody>
            <ModalFooter className={Styles.modelFooter}>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button variant="ghost" onClick={newArticle}>
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
}

export default NewPopup
