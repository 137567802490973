import React, { useState, useEffect, useMemo } from 'react';
import Styles from '../../executiveleadershipreviews.module.css'
import {
  Button, SimpleGrid, Box, GridItem, Stat, StatLabel, StatNumber,
  Spinner, Text, Flex,
} from '@chakra-ui/react';
import Select from 'react-select';
import API from '../../../../../../../../Services/API';
import parse from 'html-react-parser';
import NumberFormat from '../../../../../../../Modules/NumberFormat/NumberFormat';
import LoaderSpinner from '../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../../Widgets/ErrorWidget/ErrorWidget'

const MetricInfo = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [elrMetric, setElrMetric] = useState([]);
  const [fiscalYearList, setFiscalYearList] = useState([]);
  const [fiscalQtrList, setFiscalQtrList] = useState([]);
  const [selectedChoice, setSelectedChoice] = useState(null); // Separate state for action status
  const [selectedFiscal, setSelectedFiscal] = useState(null); // Separate state for fiscal year/quarter
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    API.get(`/fiscal-year-detail`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response => {
      setFiscalYearList(response.data?.[0]?.fiscalyear || []);
      setFiscalQtrList(response.data?.[1]?.fiscalquarter || []);
      setIsLoading(false);
    })
    .catch(() => {
      setIsLoading(false);
      setError('Failed to fetch fiscal year and quarter details. Please try again.');
    });
  }, [props.pillarId]);

  

  useEffect(() => {
    setElrMetric(props.metricResult && props.metricResult)
  }, [props.editable, props.metricResult && props.metricResult]);

  // Handle status change for fiscal year/quarter dropdown
  const statusChange = (event) => {
    if (!event || !event.value) {
      setElrMetric([]);
      return;
    }

    setSelectedFiscal(event); // Update fiscal year/quarter state

    const [prefix, value] = event.value.split('_');
    setIsLoading(true);
    const queryType = prefix === 'year' ? `fiscal_year=${value}` : `fiscal_qtr=${value}`;
    
    API.get(`/elr-sum-metric?action=${props.pillarId}&${queryType}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response => {
      setElrMetric(response.data);
      setIsLoading(false);
    })
    .catch(() => {
      setIsLoading(false);
      setError('Failed to fetch filtered metrics. Please try again.');
    });
  };

  // Handle action status change
  const handleChange = (selectedOption) => {
    setSelectedChoice(selectedOption); // Update action status state
  };

  // Save action status
  const saveAction = async () => {
    setSuccessMessage(null)
    setIsLoading(true);
    if (!selectedChoice) return;

    const formData = new FormData();
    formData.append('action_status', selectedChoice ? selectedChoice.value : '');
    formData.append('approval_status','Ready to Submit')
    const id = parseInt(props.id);
  
    if (isNaN(id)) {
      setError('Invalid ID. Please refresh the page and try again.');
      setIsLoading(false);
      return;
    }
  
    formData.append('id', id);
  
    try {
      await API.put('/elr-dashboard', formData, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      });
      statusChange()
      setSuccessMessage('Changes updated successfully.');
      props.getStatus();
    } catch (error) {
      setError('Failed to update the status. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const ACTIONSTATUS_CHOICES = [
    { value: 'on-track', label: 'On-track' },
    { value: 'delay', label: 'Delayed' },
    { value: 'acceleration', label: 'Accelerated' },
  ];

   return (
    <>
      {successMessage && <ErrorWidget message={successMessage} />}
      <GridItem colSpan={3}>
        {isLoading && <LoaderSpinner />}
        <Box p={'20px'}>
          <Box float={'right'}>
            {props.editable ? (
              <>
                <Select
                  options={ACTIONSTATUS_CHOICES}
                  onChange={handleChange}
                  placeholder="Please select the status"
                  isClearable
                  isLoading={isLoading}
                  // value={selectedChoice}
                  value={ACTIONSTATUS_CHOICES.find(choice => choice.value === (selectedChoice?.value || props.actionStatus))}
                  defaultValue={ACTIONSTATUS_CHOICES.find(choice => choice?.value === (props?.actionStatus || elrMetric && elrMetric[0]?.action_status))}
                  className={Styles.floatL}
                />
                <Button
                  onClick={saveAction}
                  disabled={isLoading || !selectedChoice || selectedChoice?.value === props.actionStatus}
                  ml={'10px'}
                  colorScheme='blue'
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <label>
                  Action execution status: 
                  <Text
                    ml={'5px'} 
                    as={'span'} 
                    backgroundColor={
                      props.actionStatus === 'on-track' ? '#50C878' : 
                      props.actionStatus === 'acceleration' ? '#FDDA0D' : 
                      props.actionStatus === 'delay' ? '#FF3131' : 'transparent'
                    }
                    color={'#ffffff'}
                    p={'10px'}
                    textTransform={'capitalize'}
                  >
                    {props.actionStatus && props.actionStatus}
                  </Text>
                </label>
                {/* <Select
                  onChange={statusChange}
                  options={flattenedOptions}
                  placeholder="Select a fiscal year or quarter"
                  isClearable
                  isLoading={isLoading}
                  value={selectedFiscal} // Bind fiscal year/quarter selection value here
                  className={Styles.floatR}
                /> */}
              </>
            )}
          </Box>
          <Box className='clearfix'></Box>
          <Box>
            {isLoading && (
              <Flex justifyContent="center" alignItems="center" height="200px">
                <Spinner size="xl" />
              </Flex>
            )}
            {error && <Box color="red" mt="10px">{error}</Box>}
            {!isLoading && !error && elrMetric.length === 0 && (
              <Box mt="10px">No metrics found for the selected fiscal year or quarter.</Box>
            )}
            {!isLoading && !error && elrMetric.length > 0 && (
              <SimpleGrid columns={[1, null, 3]} spacing='10px'>
                {elrMetric.map((metric) => (
                  <Box key={metric.id}>
                    <Stat>
                      <StatNumber>
                        {metric.data_type === 'money' ? " $ " : metric.data_type === 'number' ? " # " : null}
                        <NumberFormat number={metric.value} />
                        {metric.data_type === 'percent' ? " %" : null}
                      </StatNumber>
                      <StatLabel>
                        <Text fontWeight={'bold'}>{metric.metric_name}</Text>
                        {metric.description === '<p><br></p>' ? 'No description added' : parse(metric.description)}
                      </StatLabel>
                    </Stat>
                  </Box>
                ))}
              </SimpleGrid>
            )}
          </Box>
        </Box>
      </GridItem>
    </>
    
  );
};

export default MetricInfo;
