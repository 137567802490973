import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../aspirations.module.css'
import API from '../../../../../../Services/API';
import {Tooltip, IconButton, Text, Button} from '@chakra-ui/react'
import {useDisclosure} from '@chakra-ui/react'
import {DeleteIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const DeleteMetric = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState()
    const [deleteMessage, setDeleteMessage] = useState(false)



    const deleteAction = (event) => {
        event.stopPropagation();
        onClose()
        setIsLoading(true)
        const body = {
          "id": parseInt(props.id),
          "visible": false,
        }
        API.put(`/metric-data`,body, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
            API.get(`/metric-data?metric_id=${props.metricId}`)
            .then(response1 => {
                props.getUpdatedMetricData(response1.data)
                setIsLoading(false)
                props.deleteUpdatedMessage(true)
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
    }
  return (
    <>
        {isLoading ? <LoaderSpinner /> : null}

        <Tooltip id={props.id} label="Delete Metric" aria-label="Delete Metric">
            <IconButton
                variant="solid"
                aria-label="Edit"
                fontSize="15px"
                icon={<DeleteIcon />}
                className={Styles.edit}
                id={props.id}
                minW={'25px'}
                minH={'25px'}
                width={'25px'}
                height={'25px'}
                onClick={onOpen}
            />
        </Tooltip>



        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Confirmation</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure you want to delete <Text as='span' fontWeight={'bold'}>{props.name}</Text> ? This action cannot be reversed.
                </ModalBody>
                <ModalFooter backgroundColor={'transparent'}>
                    <Button mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button className='buttonPrimary' id={props.id} onClick={deleteAction}>Confirm </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
  )
}

export default DeleteMetric