import React, { useState, useEffect, useRef, useContext } from 'react';
import Styles from './profile.module.css';
import API from '../../Services/API';
import { FormControl, FormLabel, Text, Box, Button, Card, CardBody, Image, Input, SimpleGrid } from '@chakra-ui/react';
import BreadCrumbs from '../../Components/Widgets/BreadCrumbs/BreadCrumbs';
import LoaderSpinner from '../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import { EditContext } from '../../Context/BreadcrumbsContext';
import PlaceholderImage from '../../../assets/images/userPlaceholder.svg';
import ErrorWidget from '../../Components/Widgets/ErrorWidget/ErrorWidget';

const Profile = () => {
    const token = window.localStorage.getItem("accessToken");
    const [isLoading, setIsLoading] = useState(true);
    const [editable, setEditable] = useState(false);
    const [profile, setProfile] = useState({});
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [initialProfile, setInitialProfile] = useState({});
    const { edit, setEdit } = useContext(EditContext);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const passwordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const jobTitleRef = useRef(null);
    const webexRef = useRef(null);

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        API.get('/user-management', {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(response => {
            setProfile(response.data);
            setInitialProfile(response.data); // Store initial profile data
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            console.error('Error fetching profile:', error);
        });
    }, [token]);

    const bannerUpload = (e) => {
        setFile(e.target.files[0]);
    };

    const logoutUser = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/";
    };

    const updateProfile = () => {
        setIsLoading(true);
        setError("");
        
        const firstName = firstNameRef.current.value.trim();
        const lastName = lastNameRef.current.value.trim();
        const currentPassword = passwordRef.current ? passwordRef.current.value.trim() : "";
        const newPassword = newPasswordRef.current ? newPasswordRef.current.value.trim() : "";
        const confirmPassword = confirmPasswordRef.current ? confirmPasswordRef.current.value.trim() : "";
        const jobTitle = jobTitleRef.current.value.trim();
        const webex = webexRef.current.value.trim();

        // Field Validations
        if (!firstName) {
            setError("First name is required.");
            setIsLoading(false);
            return;
        }

        if (!lastName) {
            setError("Last name is required.");
            setIsLoading(false);
            return;
        }

        // if (currentPassword || newPassword || confirmPassword) {
        //     if (!currentPassword) {
        //         setError("Current password is required to change your password.");
        //         setIsLoading(false);
        //         return;
        //     }

        //     if (!newPassword || !confirmPassword) {
        //         setError("New password and confirm password are required.");
        //         setIsLoading(false);
        //         return;
        //     }

        //     if (newPassword !== confirmPassword) {
        //         setError("New password and confirm password do not match.");
        //         setIsLoading(false);
        //         return;
        //     }
        // }

        // Check for changes
        const hasChanges = (
            firstName !== initialProfile.first_name ||
            lastName !== initialProfile.last_name ||
            jobTitle !== initialProfile.job_title ||
            webex !== initialProfile.webex_url ||
            (newPassword && currentPassword) // If changing password, just ensure current password is filled
        );

        if (!hasChanges) {
            setError("No changes made.");
            setIsLoading(false);
            return;
        }

        // If validations pass, create the FormData object
        const formData = new FormData();
        if (file) formData.append('profile_picture', file);
        formData.append('id', profile.id);
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('job_title', jobTitle);
        formData.append('webex_url', webex);

        if (newPassword) {
            formData.append('password', newPassword);
        }

        API.put('/user-management', formData, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(response => {
            if (newPassword) {
                logoutUser();
            } else {
                return API.get('/user-management', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
            }
        }).then(response1 => {
            if (response1) {
                setProfile(response1.data);
                localStorage.setItem('user', JSON.stringify(response1.data));
                window.location.reload(false);
            }
            setIsLoading(false);
        }).catch(error => {
            console.error('Error updating profile:', error);
            setError('Failed to update profile.');
            setIsLoading(false);
        });
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            <BreadCrumbs geteditStatus={setEditable} title="Profile" />
            {error && <ErrorWidget message={error} /> }
            <Card>
                <CardBody>
                    <SimpleGrid columns={2} spacing={10}>
                        <Box>
                            <FormControl mb="10px">
                                <FormLabel fontWeight="bold">First Name *</FormLabel>
                                {edit ? (
                                    <Input type="text" ref={firstNameRef} defaultValue={profile.first_name} />
                                ) : (
                                    <Text>{profile.first_name}</Text>
                                )}
                            </FormControl>
                            <FormControl mb="10px">
                                <FormLabel fontWeight="bold">Last Name *</FormLabel>
                                {edit ? (
                                    <Input type="text" ref={lastNameRef} defaultValue={profile.last_name} />
                                ) : (
                                    <Text>{profile.last_name}</Text>
                                )}
                            </FormControl>
                            <FormControl mb="10px">
                                <FormLabel fontWeight="bold">Email *</FormLabel>
                                {edit ? (
                                    <Input type="email" defaultValue={profile.email} readOnly />
                                ) : (
                                    <Text>{profile.email}</Text>
                                )}
                            </FormControl>
                            {/* {edit && (
                                <>
                                    <FormControl mb="10px">
                                        <FormLabel fontWeight="bold">Current Password</FormLabel>
                                        <Input type="password" ref={passwordRef} />
                                    </FormControl>
                                    <FormControl mb="10px">
                                        <FormLabel fontWeight="bold">Change Password *</FormLabel>
                                        <Input type="password" ref={newPasswordRef} />
                                    </FormControl>
                                    <FormControl mb="10px">
                                        <FormLabel fontWeight="bold">Confirm Password *</FormLabel>
                                        <Input type="password" ref={confirmPasswordRef} />
                                    </FormControl>
                                </>
                            )} */}
                        </Box>

                        <Box>
                            <FormControl className={Styles.imgCon}>
                                <Image
                                    borderRadius="full"
                                    boxSize="150px"
                                    src={profile.profile_picture || PlaceholderImage}
                                    alt="User profile"
                                    border="1px solid #00a0da"
                                    m="0 auto"
                                    mb={3.5}
                                />
                            </FormControl>
                            {edit ? (
                                <FormControl mb="10px">
                                    <FormLabel fontWeight="bold">Change Profile Image</FormLabel>
                                    <Input type="file" onChange={bannerUpload} padding="4px" />
                                </FormControl>
                            ) : null}
                            <FormControl mb="10px">
                                <FormLabel fontWeight="bold">Job Title</FormLabel>
                                {edit ? (
                                    <Input type="text" ref={jobTitleRef} defaultValue={profile.job_title} />
                                ) : (
                                    <Text>{profile.job_title}</Text>
                                )}
                            </FormControl>
                            <FormControl mb="10px">
                                <FormLabel fontWeight="bold">Webex</FormLabel>
                                {edit ? (
                                    <Input type="text" ref={webexRef} defaultValue={profile.webex_url} />
                                ) : (
                                    <Text>{profile.webex_url}</Text>
                                )}
                            </FormControl>
                        </Box>
                    </SimpleGrid>
                    {edit && (
                        <Button onClick={updateProfile} className='primaryButtonNew' mt={4}>
                            Save
                        </Button>
                    )}
                </CardBody>
            </Card>
        </>
    );
};

export default Profile;
