

import { Box, HStack, Icon } from "@chakra-ui/react";
import { FiFileText, FiVideo, FiPlay } from "react-icons/fi";
import React from 'react'

const DocIcon = () => {
    return (
      <HStack spacing={0}>
        {/* Document Icon */}
        <Box>
          <Icon as={FiFileText} w={6} h={6} color="#00aae0" />
        </Box>
  
        {/* Video Icon
        <Box>
          <Icon as={FiVideo} w={8} h={8} color="red.500" />
        </Box> */}
  
        {/* Play Icon
        <Box>
          <Icon as={FiPlay} w={8} h={8} color="green.500" />
        </Box> */}
      </HStack>
    );
  };
  
  export default DocIcon;