
import React from "react";
import { DocumentViewer } from "react-documents";

const WordViewer = ({ fileUrl }) => {
  return (
    <DocumentViewer
      queryParams="hl=Nl"
      url={fileUrl}
      viewer="url"
      viewerUrl={`https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true`}
      />
  );
};

export default WordViewer;


// import React, { useEffect, useState } from 'react';
// import Mammoth from 'mammoth';

// const DocumentViewer = ({ fileUrl, containerHeight = '600px' }) => {
//   const [content, setContent] = useState('');
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchAndParseDocument = async () => {
//       try {
//         const response = await fetch(fileUrl);
//         if (!response.ok) {
//           throw new Error(`Failed to fetch document: ${response.statusText}`);
//         }

//         const arrayBuffer = await response.arrayBuffer();
//         const result = await Mammoth.convertToHtml({ arrayBuffer });
//         setContent(result.value);
//       } catch (err) {
//         console.error('Error fetching or parsing document:', err);
//         setError(err.message);
//       }
//     };

//     if (fileUrl) {
//       fetchAndParseDocument();
//     }
//   }, [fileUrl]);

//   return (
//     <div
//       style={{
//         border: '1px solid #ccc',
//         borderRadius: '8px',
//         overflow: 'auto',
//         height: containerHeight,
//         backgroundColor: '#f5f5f5',
//         padding: '20px',
//       }}
//     >
//       {error ? (
//         <div style={{ color: 'red' }}>Error: {error}</div>
//       ) : (
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           {/* Render content within a styled "page" */}
//           <div
//             style={{
//               backgroundColor: 'white',
//               width: '10.5in', // Width of a standard letter page
//               minHeight: '11in', // Height of a standard letter page
//               padding: '1in',
//               marginBottom: '20px',
//               boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
//               // fontFamily: 'Times New Roman, serif',
//               // fontSize: '12pt',
//               // lineHeight: '1.5',
//             }}
//             dangerouslySetInnerHTML={{ __html: content }}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default DocumentViewer;
