import React from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";

function getBaseUrl(signedUrl) {
  try {
    // Create a URL object to easily parse the URL
    const url = new URL(signedUrl);

    // Reconstruct the base URL without query parameters
    return `${url.origin}${url.pathname}`;
  } catch (error) {
    console.error("Invalid URL:", error);
    return null; // Return null if the URL is invalid
  }
}

const S3VideoPlayer = ({ fileUrl }) => {
  const baseUrl = getBaseUrl(fileUrl);

  if (!baseUrl) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="auto"
        background="gray.50"
      >
        <Spinner size="xl" color="blue.500" />
        <Text mt={4} fontSize="lg" color="gray.600">
          Loading video, please wait...
        </Text>
      </Box>
    );
  }

  return (
    <Box
      margin="0 auto"
      mt={8}
      p={4}
      boxShadow="lg"
      borderRadius="md"
      bg="white"
    >
      <iframe
        src={baseUrl}
        width="100%"
        height="550px"
        title="S3 Video"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ borderRadius: "8px" }}
      ></iframe>
    </Box>
  );
};

export default S3VideoPlayer;
