import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate, useLocation  } from 'react-router-dom';
import {Box, FormControl, Switch } from '@chakra-ui/react'
import Permissons from '../../Modules/Permissions/Permissons'
import Styles from './breadcrumb.module.css'

function BreadCrumbs(props) {
    const navigate = useNavigate();
    const [currentPath, setCurrentPath] = useState(window.location.pathname)
    const [pageHeading, setPageHeading] = useState()
    
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    
    useEffect(() => {
        if(currentPath === '/' || currentPath === "/dashboard"){
            setPageHeading("Dashboard")
        }else{
            var pathTitle = currentPath.split("/").pop()
            var title = pathTitle.split('-').join(' ');
            setPageHeading(title.replace(/%20/g, " "))
        }
    }, []);

    const editPage = (event) =>{
        props.geteditStatus(event.target.checked)
    }

    const containsOnlyNumbers = (str) => {
        return /^\d+$/.test(str);
    };

  return (
    <>
      <div className="page-header">
        {
          props.type === 'metric' ? 
          <h3 className="page-title text-type">
            <Link onClick={() => navigate(-1)}>
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-arrow-left"></i>
              </span>
            </Link>
            {props.title}
          </h3> : 
          <h3 className="page-title" >
            <Link onClick={() => navigate(-1)}>
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-arrow-left"></i>
              </span>
            </Link>
            {props.title}
          </h3>
        }
        

        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            {/* {(props.pillarId && props.pillarId !== "") ||
            (props.pillarId && props.pillarId !== null) ? (
              <Permissons pillarId={props.pillarId && props.pillarId} permissionType={props.permissionType}/>
            ) : null} */}
            <li style={{display:"none"}}>
              <FormControl
                display="flex"
                alignItems="center"
                className="editMain"
              >
                <Switch
                  id="email-alerts"
                  size="md"
                  defaultChecked={false}
                  onChange={editPage}
                />
              </FormControl>
            </li>
            <li className="breadcrumb-item active d-none" aria-current="page">
              <span></span>Overview{" "}
              <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default BreadCrumbs
