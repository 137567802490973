// import React, {useEffect} from 'react'
// import Styles from './listItem.module.css'
// import { Link, useParams } from 'react-router-dom';
// import { Heading, Card, Box,} from '@chakra-ui/react'
// import DeleteFaq from './DeleteFaq/DeleteFaq';

// const ListItem = (props) => {
//     const {name} = useParams();
//     const {id} = useParams();

//     return (
//         <>
//             <Box mb={'30px'}>
//                 {
//                     props.editable ?
//                         <DeleteFaq id={props.id} title={props.faqName} getUpdated={props.getUpdated} />
//                     : null
//                 }
//                 <Link className={props.editable ? Styles.customLinkButtonEdit : Styles.customLinkButton} to={`/help/faq/${id}/${name}/view/${props.id}/${props.faqName}`}>
//                     <Card height='100px' bg='white' position={'relative'} cursor={'pointer'} key={props.key} id={props.id} overflow={'hidden'} title={props.article_name}>
//                         <Heading 
//                             as='h1' 
//                             size='sm' 
//                             noOfLines={2} 
//                             position={'relative'} 
//                             top={'50%'} 
//                             className={Styles.verticalAlign}
//                             textAlign={'left'}
//                             marginLeft={'10px'}
//                         >
//                             {props.faqName}
//                         </Heading>
//                     </Card>                    
//                 </Link>
//             </Box>
//         </>
//     )
// }

// export default ListItem



import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Heading, Card, Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import DeleteFaq from './DeleteFaq/DeleteFaq';
import Styles from './listItem.module.css';

const ListItem = (props) => {
  const { name } = useParams();
  const { id } = useParams();
console.log("ListItem",props)
  return (
    <Box mb={"10px"}>
      <Accordion allowToggle>
        <AccordionItem
          border="1px solid" // Full border for the accordion item
          borderColor="gray.300"
          borderRadius="md" // Optional: Add rounded corners
          background={"#f7f7f7"}
        >
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <Link
                //   className={props.editable ? Styles.customLinkButtonEdit : Styles.customLinkButton}
                to={`/help/faq/${"253"}/${"FAQs"}/view/${props.id}/${
                  props.faqName
                }`}
              >
                {props.faqName}
              </Link>
            </Box>
            <AccordionIcon mr={5} />
            {props.editable && (
              <DeleteFaq
                id={props.id}
                title={props.faqName}
                getUpdated={props.getUpdated}
              />
            )}
          </AccordionButton>
          <AccordionPanel padding="20px" bg="white">
            {/* <Heading
              as="h1"
              fontWeight={"500"}
              size="sm"
              textAlign="left"
              dangerouslySetInnerHTML={{ __html: props.faqdes.description }}
              fontSize={{ base: "sm", md: "md" }}
            /> */}
            <Heading
              as="h1"
              fontWeight="500"
              size="sm"
              textAlign="left"
              fontSize={{ base: "sm", md: "md" }}
              sx={{
                "& div": {
                  fontWeight: "normal", // Targets the div rendered inside Heading
                },
              }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: props.faqdes.description }}
              />
            </Heading>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default ListItem;

