import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const FiscalYears = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // Redirect to the specified URL after the component mounts
        navigate('/portfolio/fy/252/Fiscal%20Year');
    }, [navigate]);

    return (
        <>

        </>
    )
}

export default FiscalYears