import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from './datafilter.modue.css'
import { Link, useParams } from 'react-router-dom'
import API from '../../../../../../Services/API';
import {Box, SimpleGrid} from '@chakra-ui/react'
import Select from 'react-select'
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const DataFilter = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const {metricId} = useParams();

    const [period, setPeriod] = useState(null);
    const [status, setStatus] = useState(null);
    const [approval, setApproval] = useState(null);
    const [periodType, setPeriodType] = useState(null);

    const [fiscalYearList, setFiscalYearList] = useState([])
    const [fiscalQtrList, setFiscalQtrList] = useState([])

    
    const options = [
        ...(props.fiscalYearDetail && props.fiscalYearDetail.length > 0 ? props.fiscalYearDetail.map(year => ({ value: year.id, label: year.name, name: 'year' })) : []),
        ...(props.fiscalYearQtrDetail && props.fiscalYearQtrDetail.length > 0 ? props.fiscalYearQtrDetail.map(quarter => ({ value: quarter.id, label: quarter.name, name: 'qtr' })) : [])
    ];

    const statusOptions =[
        {value:'on-track', label:'On Track'},
        {value:'delay', label:'Delayed'},
        {value:'acceleration', label:'Acceleration'}
    ]

    const approvalOptions =[
        {value:'pending', label:'Pending'},
        {value:'approved', label:'Approved'},
        // {value:'requirefollow-up', label:'Require Follow Up'},
        // {value:'ingovernancereview', label:'In Governance Review'}
    ]

    const periodSelect = (selectedOption) => {
        setIsLoading(true);
        if (selectedOption) {
            setPeriod(selectedOption.value.split("_")[1]);
            setPeriodType(selectedOption.name);

            const queryParams = {
                metric_id: metricId,
            };

            if (selectedOption.name === 'year') {
                queryParams.fiscal_year = selectedOption.value.split("_")[1];
            } else if (selectedOption.name === 'qtr') {
                queryParams.fiscal_quarter = selectedOption.value.split("_")[1];
            }

            if (status !== null) {
                queryParams.status = status;
            }

            if (approval !== null) {
                queryParams.approval = approval;
            }

            const queryString = new URLSearchParams(queryParams).toString();
            const fullUrl = `/get-metric-data-fiscal-year?${queryString}`;

            API.get(fullUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            })
            .then(response => {
                if (response.status === 200) {
                    props.filteredMetricData(response.data);
                } else {
                    console.error(`Unexpected response status: ${response.status}`);
                }
            })
            .catch(error => {
                console.error('Error fetching metric data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
        } else {
            setPeriod(null);
            setPeriodType(null);
            const queryParams = {
                metric_id: metricId,
            };

            if (status !== null) {
                queryParams.status = status;
            }

            if (approval !== null) {
                queryParams.approval = approval;
            }

            if(status !== null || approval !== null){
                const queryString = new URLSearchParams(queryParams).toString();
                const fullUrl = `/get-metric-data-fiscal-year?${queryString}`;
                API.get(fullUrl, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                })
                .then(response => {
                    if (response.status === 200) {
                        props.filteredMetricData(response.data);
                    } else {
                        console.error(`Unexpected response status: ${response.status}`);
                    }
                })
                .catch(error => {
                    console.error('Error fetching metric data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }else {
                API.get(`/metric-data?metric_id=${metricId}`)
                .then(response=>{
                    props.filteredMetricData(response.data)
                    setIsLoading(false)
                }).catch(error=>{
                    setIsLoading(false)
                })
            }
        }
    };

    const statusSelect = (selectedOption) =>{
        setIsLoading(true)
        if (selectedOption) {
            setStatus(selectedOption.value)
            let selectedPeriod = period
            let selectedStatus = selectedOption.value
            let selectedApproval = approval
    
            let url = `/get-metric-data-fiscal-year`;
            const queryParams = {
                metric_id: metricId,
                status: selectedOption.value
            };
    
            if(period !== null){
                if (periodType === 'year') {
                    queryParams.fiscal_year = period;
                } else if (periodType === 'qtr') {
                    queryParams.fiscal_quarter = period;
                }
            }
            if (selectedApproval !== null) {
                queryParams.approval = selectedApproval;
            }
            const queryString = new URLSearchParams(queryParams).toString();
            const fullUrl = `${url}?${queryString}`;
            API.get(fullUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                if (response.status === 200) {
                    props.filteredMetricData(response.data)
                    setIsLoading(false)
                } else {
                    console.error(`Unexpected response status: ${response.status}`);
                    setIsLoading(false)
                }
            }).catch(error => {
                console.error('Error fetching metric data:', error);
                setIsLoading(false)
            });
        }else {
            setStatus(null)
            const queryParams = {
                metric_id: metricId,
            };

            if(period !== null){
                if (periodType === 'year') {
                    queryParams.fiscal_year = period;
                } else if (periodType === 'qtr') {
                    queryParams.fiscal_quarter = period;
                }
            }

            if (approval !== null) {
                queryParams.approval = approval;
            }

            if(period !== null || approval !== null){
                const queryString = new URLSearchParams(queryParams).toString();
                const fullUrl = `/get-metric-data-fiscal-year?${queryString}`;
                API.get(fullUrl, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                })
                .then(response => {
                    if (response.status === 200) {
                        props.filteredMetricData(response.data);
                    } else {
                        console.error(`Unexpected response status: ${response.status}`);
                    }
                })
                .catch(error => {
                    console.error('Error fetching metric data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }else {
                API.get(`/metric-data?metric_id=${metricId}`)
                .then(response=>{
                    props.filteredMetricData(response.data)
                    setIsLoading(false)
                }).catch(error=>{
                    setIsLoading(false)
                })
            }
        }
    }

    const approvalSelect = (selectedOption) =>{
        setIsLoading(true)
        if (selectedOption) {
            setApproval(selectedOption.value)
            let selectedPeriod = period
            let selectedStatus = status
            let selectedApproval = selectedOption.value
    
            let url = `/get-metric-data-fiscal-year`;
            const queryParams = {
                metric_id: metricId,
                approval: selectedOption.value
            };
    
            if(period !== null){
                if (periodType === 'year') {
                    queryParams.fiscal_year = period;
                } else if (periodType === 'qtr') {
                    queryParams.fiscal_quarter = period;
                }
            }
    
            if (selectedStatus !== null) {
                queryParams.status = selectedStatus;
            }
    
            
    
            const queryString = new URLSearchParams(queryParams).toString();
            const fullUrl = `${url}?${queryString}`;
    
            API.get(fullUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                if (response.status === 200) {
                    props.filteredMetricData(response.data)
                    setIsLoading(false)
                } else {
                    console.error(`Unexpected response status: ${response.status}`);
                    setIsLoading(false)
                }
            }).catch(error => {
                console.error('Error fetching metric data:', error);
                setIsLoading(false)
            });
        }else {
            setApproval(null)
            
            const queryParams = {
                metric_id: metricId,
            };

            if(period !== null){
                if (periodType === 'year') {
                    queryParams.fiscal_year = period;
                } else if (periodType === 'qtr') {
                    queryParams.fiscal_quarter = period;
                }
            }

            if (status !== null) {
                queryParams.status = status;
            }

            if(period !== null || status !== null){
                const queryString = new URLSearchParams(queryParams).toString();
                const fullUrl = `/get-metric-data-fiscal-year?${queryString}`;
                API.get(fullUrl, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                })
                .then(response => {
                    if (response.status === 200) {
                        props.filteredMetricData(response.data);
                    } else {
                        console.error(`Unexpected response status: ${response.status}`);
                    }
                })
                .catch(error => {
                    console.error('Error fetching metric data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }else {
                API.get(`/metric-data?metric_id=${metricId}`)
                .then(response=>{
                    props.filteredMetricData(response.data)
                    setIsLoading(false)
                }).catch(error=>{
                    setIsLoading(false)
                })
            }
        }
       
    }

    const flattenedOptions = useMemo(() => {
        if (!props.fiscalYearDetail || !props.fiscalYearQtrDetail) {
            return [];
        }
        return props.fiscalYearDetail
            .slice()
            .reverse()
            .reduce((acc, year) => {
                acc.push({ value: `year_${year.id}`, label: year.name, name: 'year' });
                const quarterOptions = props.fiscalYearQtrDetail
                    .filter(quarter => quarter.name.includes(year.name.slice(-2)))
                    .map(quarter => ({
                        value: `qtr_${quarter.id}`,
                        label: quarter.name,
                        name: 'qtr'
                    }));
                return acc.concat(quarterOptions);
            }, []);
    }, [props.fiscalYearDetail, props.fiscalYearQtrDetail]);
    

    const sortedOptions = [...flattenedOptions].sort((a, b) => {
        const yearA = a.label.match(/FY(\d+)/);
        const yearB = b.label.match(/FY(\d+)/);
        const yearNumA = yearA ? parseInt(yearA[1], 10) : 0;
        const yearNumB = yearB ? parseInt(yearB[1], 10) : 0;
        if (yearNumA === yearNumB) {
            if (a.name === 'year' && b.name === 'qtr') return -1;
            if (a.name === 'qtr' && b.name === 'year') return 1;
            const quarterA = a.label.match(/Q(\d+)/);
            const quarterB = b.label.match(/Q(\d+)/);
            if (quarterA && quarterB) {
                return parseInt(quarterB[1], 10) - parseInt(quarterA[1], 10);
            }
        }
        return yearNumB - yearNumA;
    });
    
    return (
        <>
            {isLoading ? <LoaderSpinner /> : null }
            <SimpleGrid columns={[1, 2, 3, 4]} spacing='40px' mb={'15px'}>
                {/* {metricId} */}
                <Box>
                    <Select
                        options={sortedOptions}
                        placeholder={'Period (FY/QTR)'}
                        onChange={periodSelect}
                        isClearable
                    />
                </Box>
                <Box>
                    <Select
                        options={statusOptions}
                        placeholder={'Filter by Status'}
                        onChange={statusSelect}
                        isClearable
                    />
                </Box>
                <Box>
                    <Select
                        options={approvalOptions}
                        placeholder={'Filter by Approval'}
                        onChange={approvalSelect}
                        isClearable
                    />
                </Box>
            </SimpleGrid>
        </>
        
    )
}

export default DataFilter