import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Box, FormLabel, Button } from '@chakra-ui/react';
import API from '../../../../../../../../Services/API';
import LoaderSpinner from '../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../../Widgets/ErrorWidget/ErrorWidget';

const FiscalYear = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const [fiscalYearList, setFiscalYearList] = useState([]);
  const [fiscalQtrList, setFiscalQtrList] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
  const [selectedFiscalQtr, setSelectedFiscalQtr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [getQtrList, setGetQtrList] = useState([]);

  useEffect(() => {
    API.get(`/fiscal-year-detail`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response => {
      const yearList = response.data[0].fiscalyear.map(item => ({ value: item.id, label: item.name }));
      const quarterList = response.data[1].fiscalquarter.map(item => ({ value: item.id, label: item.name }));
      setFiscalYearList(yearList);
      setFiscalQtrList(quarterList);

      if (props.fiscalYear) {
        const defaultYear = yearList.find(year => year.label === props.fiscalYear);
        setSelectedFiscalYear(defaultYear || null);
      }

      if (props.fiscalQtr) {
        const defaultQtr = quarterList.find(qtr => qtr.label === props.fiscalQtr);
        setSelectedFiscalQtr(defaultQtr || null);
      }

      const SelectedOptionNew = `FY${props.fiscalYear.slice(-2)}`;
      let relatedQuarters = fiscalQtrList.filter(item => item.label.includes(SelectedOptionNew));
      relatedQuarters = relatedQuarters.sort((a, b) => {
        const quarterA = parseInt(a.label.split(' ')[1].replace('Q', ''));
        const quarterB = parseInt(b.label.split(' ')[1].replace('Q', ''));
        return quarterB - quarterA;
      });

      setGetQtrList(relatedQuarters);

    }).catch(error => {
      setIsLoading(false);
    });
  }, [token, props.editable]);

  const handleFiscalYearChange = (selectedOption) => {
    console.log("option",selectedOption,getQtrList)
    setSelectedFiscalYear(selectedOption);
    setSelectedFiscalQtr(null);
    setGetQtrList([]);
    if (selectedOption) { // Check if selectedOption is not null
      const SelectedOptionNew = `FY${selectedOption.label.slice(-2)}`;
      let relatedQuarters = fiscalQtrList.filter(item => item.label.includes(SelectedOptionNew));
      relatedQuarters = relatedQuarters.sort((a, b) => {
        const quarterA = parseInt(a.label.split(' ')[1].replace('Q', ''));
        const quarterB = parseInt(b.label.split(' ')[1].replace('Q', ''));
        return quarterB - quarterA;
      });
      
      setGetQtrList(relatedQuarters);
      
      if (selectedOption.label === props.fiscalYear) {
        const defaultQtr = fiscalQtrList.find(qtr => qtr.label === props.fiscalQtr);
        setSelectedFiscalQtr(defaultQtr || null);
      }
    } else {
        // If selectedOption is null, reset related quarters
        setGetQtrList([]);
    }
  };

  const handleFiscalQtrChange = (selectedOption) => {
      setSelectedFiscalQtr(selectedOption);
  };

  const saveAction = (event) => {
    setInfoMessage(null)
    setSuccessMessage(null)
    setErrorMessage(null)
    setIsLoading(true);
    if(props.slideType === 'slide1'){
      if((props.fiscalYear !== selectedFiscalYear?.label) || (props.fiscalQtr !== selectedFiscalQtr?.label)){
        const formData = new FormData();
        formData.append('id', parseInt(event.target.id));
        formData.append('action', parseInt(props.pillarId));
        formData.append('fiscal_year', selectedFiscalYear && selectedFiscalYear.value);
        formData.append('fiscal_year_name', selectedFiscalYear && selectedFiscalYear.label);
        
      if (typeof selectedFiscalQtr?.value !== "undefined") {
        formData.append(
          "fiscal_quarter",
          selectedFiscalQtr != null && selectedFiscalQtr?.value
        );
        formData.append(
          "fiscal_quarter_name",
          selectedFiscalYear && selectedFiscalYear.label
        );
      }
        formData.append('approval_status','Ready to Submit')
        API.put('/elr-dashboard', formData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          setSuccessMessage('Changes updated successfully.')
          props.slideOneUpdate()
          setIsLoading(false);
        }).catch(error => {
          console.log(error)
          setIsLoading(false);
          setErrorMessage(
            error.response.data.msg ? error.response.data.msg : 
            error.response.data.fiscal_quarter ? error.response.data.fiscal_quarter[0]: 
            error.response.data.fiscal_year ? error.response.data.fiscal_year[0]: 
            error.response.data.error
          );
        });
      }else{
        setInfoMessage('No changes are made')
        setIsLoading(false);
      }
    }else if(props.slideType === 'slide2'){
      if((props.fiscalYear !== selectedFiscalYear?.label) || (props.fiscalQtr !== selectedFiscalQtr?.label)){
        const formData = new FormData();
        formData.append('fiscal_year', selectedFiscalYear.value);
        formData.append('fiscal_quarter',selectedFiscalQtr && selectedFiscalQtr?.value);
        formData.append('id', parseInt(event.target.id));
        formData.append('action', parseInt(props.pillarId));
        formData.append('approval_status','Ready to Submit')
        const apiEndpoint = '/impact-story'
        API.put(apiEndpoint, formData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          setSuccessMessage('Changes updated successfully.')
          props.getslide2Update()
          setIsLoading(false);
        }).catch(error => {
          setIsLoading(false);
          setErrorMessage(
            error.response.data.msg ? error.response.data.msg : 
            error.response.data.fiscal_quarter ? error.response.data.fiscal_quarter[0]: 
            error.response.data.fiscal_year ? error.response.data.fiscal_year[0]: 
            error.response.data.error
          );
        });
      }else{
        setInfoMessage('No changes are made')
        setIsLoading(false);
      }
    }else if(props.slideType === 'slide3'){
      if((props.fiscalYear !== selectedFiscalYear?.label) || (props.fiscalQtr !== selectedFiscalQtr?.label)){
        const formData = new FormData();
        formData.append('fiscal_year', selectedFiscalYear.value);
        formData.append('fiscal_quarter', selectedFiscalQtr.value);
        formData.append('id', parseInt(event.target.id));
        formData.append('action', parseInt(props.pillarId));
        formData.append('approval_status','Ready to Submit')
        const apiEndpoint = '/executive-level-review'
        API.put(apiEndpoint, formData, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          setSuccessMessage('Changes updated successfully.')
          props.getslide3Update()
          setIsLoading(false);
        }).catch(error => {
          setIsLoading(false);
          setErrorMessage(
            error.response.data.msg ? error.response.data.msg : 
            error.response.data.fiscal_quarter ? error.response.data.fiscal_quarter[0]: 
            error.response.data.fiscal_year ? error.response.data.fiscal_year[0]: 
            error.response.data.error
          );
        });
      }else{
        setInfoMessage('No changes are made')
        setIsLoading(false);
      }
    }
  };

  const sortedFiscalYearList = fiscalYearList.sort((a, b) => {
    const getFiscalYear = (label) => {
        const match = label.match(/FY(\d+)/);
        return match ? parseInt(match[1], 10) : -1;
    };
    const getQuarter = (label) => {
        const match = label.match(/Q(\d+)/);
        return match ? parseInt(match[1], 10) : 0; // Default 0 for no quarter
    };
    const fiscalYearDiff = getFiscalYear(b.label) - getFiscalYear(a.label);
    if (fiscalYearDiff !== 0) return fiscalYearDiff;
    return getQuarter(b.label) - getQuarter(a.label);
  });

  return (
    <>
      {isLoading ? <LoaderSpinner /> : null}
      {successMessage && <ErrorWidget message={successMessage} />}
      {errorMessage && <ErrorWidget message={errorMessage} />}
      {infoMessage && <ErrorWidget message={infoMessage} />}
      {props.editable && (
        <>
          <Box p={'5px'}>
            <FormLabel fontWeight={'600'}>Select Fiscal Year</FormLabel>
            <Select
              id={'metricData_targetfiscalyear'}
              options={sortedFiscalYearList}
              value={selectedFiscalYear}
              onChange={handleFiscalYearChange}
              placeholder="Select fiscal year"
              isClearable // Enable clearable option
            />
            <Box className='clearfix'></Box>
          </Box>

          <Box p={'5px'}>
            <FormLabel fontWeight={'600'}>Select Fiscal Qtr</FormLabel>
            <Select
              id={'metricData_targetfiscalquarter'}
              options={selectedFiscalYear ? getQtrList : []}
              value={selectedFiscalQtr}
              onChange={handleFiscalQtrChange}
              placeholder={selectedFiscalYear ? "Select fiscal quarter" : "Select fiscal year first"}
              isDisabled={!selectedFiscalYear} // Disable if no fiscal year is selected
              isClearable // Enable clearable option
            />
            <Button className='buttonPrimary' float={'right'} id={props.id} mt={'10px'} onClick={saveAction}>Save</Button>
            <Box className='clearfix'></Box>
          </Box>
        </>
      )}
    </>
  );
};

export default FiscalYear;
